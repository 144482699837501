import React, { useState } from 'react';
import MyBook from './Book';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
   background-color: rgba(50,50,50);
  }
`;

const BookContainer = styled.div`
  background-color: rgba(90, 90, 90);
  height: 100vh;
`;

function App() {
  return (
    <>
      <GlobalStyle />
      <BookContainer>
        <MyBook />
      </BookContainer>
    </>
  );
}

export default App;
