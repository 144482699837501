import { useRef, useEffect, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import styled from 'styled-components';

const images = [
  '20231012_CMH_Fundraising.jpg',
  '20231012_CMH_Fundraising2.jpg',
  '20231012_CMH_Fundraising3.jpg',
  '20231012_CMH_Fundraising4.jpg',
  '20231012_CMH_Fundraising5.jpg',
  '20231012_CMH_Fundraising6.jpg',
  '20231012_CMH_Fundraising7.jpg',
  '20231012_CMH_Fundraising8.jpg',
];

const PageDiv = styled.div`
  background-color: gray;
  background-image: url(${(props) => props.file});
  background-size: cover;
  width: auto;
  height: 100%;
`;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function MyBook(children, props) {
  const book = useRef();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (process.env.REACT_APP_AUTO) {
      console.log('Running App in AutoFlip Mode');
      const intervalId = setInterval(
        () => book.current.pageFlip().flipNext(),
        3000
      );

      return () => clearInterval(intervalId); //This is important
    }
  }, [book]);

  return (
    <>
      <HTMLFlipBook ref={book} width={835} height={1080} size='stretch'>
        <PageDiv
          className='page page-cover'
          file={`${process.env.PUBLIC_URL}/cmh/${images[0]}`}
        />
        <PageDiv
          className='page page-cover'
          file={`${process.env.PUBLIC_URL}/cmh/${images[1]}`}
        />
        {images.slice(2).map((img, id) => {
          return (
            <PageDiv
              className='page-content'
              width={width}
              file={`${process.env.PUBLIC_URL}/cmh/${img}`}
            />
          );
        })}
      </HTMLFlipBook>
    </>
  );
}

export default MyBook;
